


























import { Component, Vue, Prop } from 'vue-property-decorator'

import { onLogout } from '@/vue-apollo'
import { IMe } from '@/graphql/queries/interfaces/Me.interface'

import { RoleName } from '@/lib/interfaces/RoleName.enum'

@Component<Header>({})
export default class Header extends Vue {
  menuContainer = false

  get isLoggedIn() {
    return this.$store.state.me != null
  }

  get isOrganizer() {
    const me = this.$store.state.me as IMe
    return me != null && me.type === RoleName.Organizer
  }

  get isCompany() {
    const me = this.$store.state.me as IMe
    return me != null && me.type === RoleName.Company
  }

  get username() {
    const me = this.$store.state.me as IMe
    return `${me.forename} ${me.name || ''}`
  }

  openContainer() {
    this.menuContainer = !this.menuContainer
  }

  async logout() {
    await onLogout(this.$apollo.provider.defaultClient)
    this.$store.commit('me', undefined)
    this.$router.push({ name: 'login' })
    this.$toast.open({ type: 'info', message: `${this.$t('toast.Goodbye')}` })
  }
}
